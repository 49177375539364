import * as Sentry from '@sentry/browser'

const sentryData = document.head.querySelector('meta[name="app-data"]').dataset

if (sentryData.sentryDsn) {
  Sentry.init({
    dsn: sentryData.sentryDsn,
    release: sentryData.sentryRelease,
    environment: sentryData.sentryEnv
  })

  Sentry.configureScope(function(scope) {
    scope.setUser({ id: sentryData.userId })
  });
}
